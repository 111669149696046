<template>
    <div class="md_main">
        <div class="md_top">
            <div class="md_top_son">
                <el-breadcrumb separator="/" style="padding-top: 13px">
                    <el-breadcrumb-item><router-link :to="{path:'/'}">首页</router-link></el-breadcrumb-item>
                    <el-breadcrumb-item>解决方案</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="mdm_son">
            <div class="mdm_l">
                <img src="../image/text1.png">
            </div>
            <div class="mdm_r">
                <div class="mdr_1">微模块</div>
                <div class="mdr_2">
                    HiflexAisle系列模块化数据中心基础设施解决方案为数据中心IT设备提供一个整合的、标准的、优质的、智能的基础设施环境，是一种全新的高效节能数据中心建设模式。模块化数据中心基础设施独特的设计，使其在支持现有业务需求的同时，还可在未来不断灵活扩展以容纳新的业务需求，避免当前产生不必要的投资浪费。工厂统一预制模块化的建设模式保证了产品的优良制造工艺。单模块的IT总功耗不超过145kW，适用于300平米以下的中小型数据中心，也可应用于大型企业分支机构的数据中心。而采用多个模块构建的中大型数据中心，可满足政府、教育、医疗、金融、电信等行业大型数据中心的需求。
                    产品分类
                </div>

                <!--<div class="pdr_3">
                    <span>机柜尺寸</span>
                    <div>
                        <el-radio v-model="radio1" label="1" border>备选项1</el-radio>
                        <el-radio v-model="radio1" label="2" border>备选项2</el-radio>
                    </div>
                </div>-->
                <div class="mdr_4" style="float: left" @click="bIsShowIs">标准配置</div>
                <router-link :to="{path:'/requirement'}"><div class="mdr_3" style="float: left">自定义配置</div></router-link>

                <div v-show="bIsShow" style="clear: both;padding-top: 10px;">
                    <div class="mdr_5">
                        <span>机柜系列</span>
                        <el-select v-model="formData.cabinetType" placeholder="请选择">

                            <el-option label="X3"  value="X3"></el-option>
                            <el-option label="X5"  value="X5"></el-option>
                            <el-option label="X7"  value="X7"></el-option>
                        </el-select>
                    </div>
                    <div class="mdr_5">
                        <span>机柜尺寸</span>
                        <el-select v-model="size" placeholder="请选择">
                            <el-option label="600*1200*42U"  value="600*1200*42"></el-option>
                            <el-option label="600*1200*47U"  value="600*1200*47"></el-option>
                        </el-select>
                        <span style="width: 200px;padding-left: 20px">备电时间：30min</span>

                    </div>
                    <!--<div class="mdr_5">
                        <span>机柜尺寸宽</span>
                        <el-select v-model="formData.cabinet1Width" placeholder="请选择">
                            <el-option label="600"  value="600"></el-option>
                            <el-option label="800"  value="800"></el-option>
                        </el-select>
                    </div>
                    <div class="mdr_5">
                        <span>机柜尺寸深</span>
                        <el-select v-model="formData.cabinet1Depth" placeholder="请选择">
                            <el-option label="1100"  value="1100"></el-option>
                            <el-option label="1200"  value="1200"></el-option>
                        </el-select>
                    </div>
                    <div class="mdr_5">
                        <span>机柜尺寸高</span>
                        <el-select v-model="formData.cabinet1Height" placeholder="请选择">
                            <el-option label="2000"  value="2000"></el-option>
                            <el-option label="2200"  value="2200"></el-option>
                        </el-select>
                    </div>-->
                    <div class="mdr_5">
                        <span>单柜功耗</span>
                        <el-select v-model="formData.cabinet1Power" placeholder="请选择">
                            <el-option label="3"  value="3"></el-option>
                            <el-option label="5"  value="5"></el-option>
                            <el-option label="8"  value="8"></el-option>
                        </el-select>
                    </div>
                    <div class="mdr_5">
                        <span>市电概况</span>
                        <el-select v-model="formData.desUpsPowerType" placeholder="请选择">
                            <el-option label="单路"  value="单路"></el-option>
                            <el-option label="双路"  value="双路"></el-option>
                        </el-select>
                    </div>
                    <div class="mdr_5">
                        <span>机柜数量</span>

                        <el-select v-model="formData.cabinet1Count" placeholder="请填写数量">
                            <el-option label="11"  value="11"></el-option>
                            <el-option label="21"  value="21"></el-option>
                            <el-option label="31"  value="31"></el-option>
                            <el-option label="40"  value="40"></el-option>
                        </el-select>
                    </div>
                    <!--<div class="pdr_4">标准化产品参数</div>
                    <div class="pdr_3">
                        &lt;!&ndash;<el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>&ndash;&gt;
                        <div>
                            <el-radio-group v-model="radio1" @change="radioParChange">
                                <el-radio  v-for="item in optionsPar"   :label="item.id" border>{{item.name}}</el-radio>
                            </el-radio-group>


                        </div>
                    </div>
                    <div style="width: 100%;height: 2px;background: #EFEFEF;    margin-top: 30px; "></div>
                    <div class="pdr_3">

                        <div>
                            <el-radio-group v-model="radio2">
                                <el-radio  v-for="item in optionsSon"  :label="item.id" border>{{item.name}}</el-radio>
                            </el-radio-group>

                        </div>
                    </div>-->
                    <!--<div class="mdr_5">
                        <span>盲板</span>
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>-->
                    <el-button type="danger" class="mdr_6" @click="openDia" :loading="btnLoading">生成清单</el-button>
                </div>

            </div>
        </div>
        <el-dialog title="选择项目" :visible.sync="dialogVisible" width="370px">
            <el-form  label-width="80px">
                <el-form-item v-show="addShow"><el-button @click="openAddShow">新建项目</el-button></el-form-item>
                <el-form-item v-show="!addShow"><el-button @click="closeAddShow">返回</el-button></el-form-item>
                <el-form-item label="已有项目" v-show="addShow">
                    <el-select v-model="xmList" placeholder="请选择项目">
                        <el-option
                                v-for="item in listChange"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称" v-show="!addShow">
                    <el-input v-model="xmListNew" placeholder="请输入项目名称"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button v-show="addShow" type="primary" @click="goDetial" >确 定</el-button>
                <el-button v-show="!addShow" type="primary" @click="goDetialAdd" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        standardList,
        projectList,
        projectAdd,
    } from "@/Api/finance/home";
    import { encrypt,decrypt } from "@/Util/auth";
    export default {
        name: "index",
        data() {
            return {
                formData:{

                    "cabinet1Count": "11", //柜体1数量
                    "cabinet1Depth": "1200", //柜体1深度
                    "cabinet1UCount": "", //柜体1高度
                    "cabinet1Power": "5", //柜体1功率
                    "cabinet1Width": "600", //柜体1宽度
                    "cabinetType": "X5", //柜体类型
                    "desUpsPowerType": "双路", //市电输入
                },
                btnLoading: false,
                bIsShow: false,
                listChange: [],
                radio1: '',
                radio2: '',
                optionsPar:[],
                optionsSon:[],
                dialogVisible:false,
                xmList:'',
                xmListNew:'',
                addShow:true,
                size:'600*1200*42',
            }
        },
        mounted() {
           /* this.getstandardList()
            this.getprojectList()*/

           /* let txt = 'type=pdf'

            text(txt).then(res => {

                this.exportExcle('123',res)
                /!*let dd= JSON.parse(decrypt(res))
                if (dd.code == 200) {

                }else {
                    alert(dd.msg);
                }*!/

            })*/
        },
        methods: {
            bIsShowIs(){
                this.bIsShow=!this.bIsShow
            },
            goDetialAdd(){
                if(this.xmListNew==''){
                    alert('请新增项目')
                }else {
                    this.btnLoading = true;
                    let obj = {
                        name: this.xmListNew,
                    };
                    let ss= encrypt(JSON.stringify(obj))
                    projectAdd(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            alert(dd.msg);
                            this.btnLoading =false
                            this.$router.push({name:'configurationInfo',query: {id:this.radio2,name:dd.id,type:this.$route.query.type}})

                        }else {
                            this.btnLoading =false
                            alert(dd.msg);
                        }

                    })
                }

            },
            goDetial(){
                if(this.xmList==''){
                    alert('请选择项目')
                }else {
                    this.$router.push({name:'configurationInfo',query: {id:this.radio2,name:this.xmList,type:this.$route.query.type}})
                }

            },
            openDia(){
                //this.dialogVisible=true

                this.btnLoading = true;
                if(this.size=='600*1200*47'){
                    this.formData.cabinet1UCount='47'
                }else {
                    this.formData.cabinet1UCount='42'
                }
                let obj = this.formData
                let ss= encrypt(JSON.stringify(obj))
                projectAdd(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    console.log(dd)
                    if (dd.code == 200) {
                        /*alert(dd.msg);*/
                        this.btnLoading =false
                        //this.$router.push({name:'configurationInfo',query: {id:this.radio2,name:dd.id,type:this.$route.query.type}})
                        //sessionStorage.setItem("configurationInfoList", JSON.stringify(dd.data));
                        this.$router.push({name:'configurationInfo',query: {id:dd.data}})
                    }else {
                        this.btnLoading =false
                        alert(dd.msg);
                    }

                })
            },
            openAddShow(){
                this.addShow=false
                this.xmListNew=''
                this.xmList=''
            },
            closeAddShow(){
                this.addShow=true
                this.xmListNew=''
                this.xmList=''
            },
           /* radioParChange(val){
                this.optionsPar.map((item,index)=>{
                    if(item.id==val){
                        this.optionsSon=item.list
                        if(this.optionsSon.length>0){
                            this.radio2=this.optionsSon[0].id
                        }
                    }
                })
            },
            getstandardList(){
                let parmars={
                    type:this.$route.query.type,
                }
                let parmarsCode= encrypt(JSON.stringify(parmars))
                standardList(parmarsCode).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.optionsPar=dd.data
                        if(this.optionsPar.length>0){
                            this.radio1=this.optionsPar[0].id
                            if(this.optionsPar[0].list.length>0){
                                this.optionsSon=this.optionsPar[0].list
                                if(this.optionsSon.length>0){
                                    this.radio2=this.optionsSon[0].id
                                }
                            }
                        }
                    }else {
                        alert(dd.msg);
                    }

                })

            },
            getprojectList(){
                let obj = {
                    page: '1',
                    size: '99999'
                };
                let ss= encrypt(JSON.stringify(obj))
                projectList(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.listChange=dd.data.records
                    }else {
                        alert(dd.msg);
                    }

                })

            },*/
        }
    }
</script>

<style lang="less" scoped>
    .mdr_3{
        cursor: pointer;
        width: 165px;
        height: 50px;margin-left: 30px;
        border-radius: 5px;
        line-height: 50px;
        color: white;margin-top: 50px;
        text-align: center;font-size: 18px;
        background: url("../image/w_icon.png") 13px 13px rgba(32, 149, 238, 1) no-repeat;
        padding-left: 25px;
    }
    .mdr_4{
        cursor: pointer;
        width: 165px;
        height: 50px;
        margin-top: 50px;

        background: #EDEDED;
        border: 1px solid #333333;
        border-radius: 5px;
        line-height: 50px;
        color: #333333;
        text-align: center;font-size: 18px;
        background: url("../image/b_icon.png") 13px 13px rgba(237, 237, 237, 1) no-repeat;
        padding-left: 15px;
    }
    .md_main{
        background: white;
        height: 100%;
        display: flow-root;
        min-height: 529px;
        .md_top{
            width: 100%;
            background: #EEEEEE;
            height: 42px;
            border: 1px solid #E5E5E5;
            .md_top_son{
                width: 1200px;
                margin: 0px auto;
                font-size: 16px;
                color: #999999;
                line-height: 42px;

            }
        }
        .mdm_son{
            width: 1200px;
            margin: 0px auto;
            .mdm_l{
                float: left;
                margin-top: 20px;
                width: 532px;
                height: 331px;
            }
            .mdm_r{
                float: left;
                width: 655px;
                .mdr_1{
                    font-size: 24px;
                    font-weight: bold;
                    color: #333333;
                    margin-top: 60px;
                }
                .mdr_2{
                    color: #999999;
                    font-size: 16px;
                    margin-top: 20px;
                    line-height: 30px;
                }
                .pdr_3{
                    margin-top: 36px;
                   /deep/ .el-radio{
                        margin-left: 0px;
                        margin-bottom: 10px;
                    }
                    span{
                        float: left;font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        height: 36px;
                        line-height: 36px;
                        margin-right: 45px;
                    }
                }
                .pdr_4{
                    width: 302px;
                    height: 42px;margin-top: 20px;
                    background: #EEEEEE;
                    color: #2095EE;font-size: 16px;
                    padding-left: 20px;
                    line-height: 42px;
                    border-left: 2px solid #2095EE;
                }
                .mdr_5{
                    margin-top: 36px;
                    height: 36px;
                    span{
                        float: left;font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        height: 36px;
                        line-height: 36px;
                        width: 100px;
                    }
                    .el-select,.el-input{
                        float: left;
                    }
                }
                .mdr_6{
                    margin-top: 60px;
                    margin-bottom: 60px;
                    width: 165px;
                    height: 50px;
                    background: #E72A32;
                    border-radius: 5px;font-size: 18px;

                }
            }
        }
    }
</style>
